import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Contact from '../components/Contact'

import * as S from '../components/InfoPost/styled'

const BlogPost = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <S.InfoWrapper>
        <SEO title={post.frontmatter.title} />
        <S.Meta>
          <h5>{post.frontmatter.tag}</h5>
          <S.Title>{post.frontmatter.title}</S.Title>
          <h5>{post.frontmatter.date}</h5>
        </S.Meta>
        <div
          dangerouslySetInnerHTML={{
            __html: post.html
          }}
        />
      </S.InfoWrapper>
      <Contact />
    </Layout>
  )
}

export const query = graphql`
  query Post($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
        tag
      }
      id
      html
    }
  }
`

export default BlogPost
