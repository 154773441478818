import styled from 'styled-components'

export const InfoWrapper = styled.div`
  padding: 140px 20px 60px;
  max-width: 1000px;
  margin: auto;
`

export const Meta = styled.div`
  text-align: center;
  margin-bottom: 40px;
`

export const Title = styled.h1`
  margin-bottom: 0;
`
